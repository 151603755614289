
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,800');

@import "settings";
@import "foundation";
@import "motion-ui";

@include foundation-flex-grid;
@include foundation-global-styles;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-flex-classes;
// @include foundation-accordion;
// @include foundation-accordion-menu;
// @include foundation-badge;
// @include foundation-breadcrumbs;
// @include foundation-button-group;
// @include foundation-callout;
// @include foundation-close-button;
// @include foundation-drilldown-menu;
// @include foundation-dropdown;
// @include foundation-dropdown-menu;
// @include foundation-flex-video;
// @include foundation-label;
// @include foundation-media-object;
// @include foundation-menu;
// @include foundation-off-canvas;
// @include foundation-orbit;
// @include foundation-pagination;
// @include foundation-progress-bar;
// @include foundation-slider;
// @include foundation-sticky;
// @include foundation-reveal;
// @include foundation-switch;
// @include foundation-table;
// @include foundation-tabs;
// @include foundation-thumbnail;
// @include foundation-title-bar;
// @include foundation-tooltip;
// @include foundation-top-bar;

@include motion-ui-transitions;
@include motion-ui-animations;

h1 {
  font-size: 2.5rem;
}

#page-title {
  display: none;
}

h2 {
  font-size: 1.5rem;
}

.item-list ul li {
  margin: 0 0 0 1.5em;
}

body:not(.cke_editable) {
  background: $footer-bg;
}

header {
  $header-border-width: .25rem;
  background-color: $white;
  border-bottom: $header-border-width solid $light-gray;
  width: 100%;
  padding: .5rem 0;
  display: block;
  position: relative;

  img.logo {
    height: 3.25rem;
    width: auto;
    margin-right: 1rem;

    @include breakpoint(medium) {
      height: 4.25rem;
    }
  }

  .logo-text {
    display: inline-block;
    position: relative;
    top: .7rem;
    font-weight: 300;

    a {
      color: $body-font-color;
    }
  }

  .menu-trigger-wrap {
    padding-right: 1rem;
    padding-left: 1rem;

    @include breakpoint(medium) {
      padding-left: 0;
    }
  }

  .menu-trigger {
    font-weight: 600;

    .menu-trigger-icon {
      @include hamburger($primary, $primary, 22px, 22px, 2px, 3);
      margin-left: .5rem;
    }
  }

  .mobile-menu {
    background: $primary;
    padding: 1rem 0;
    position: absolute;
    width: 100%;
    top: calc(100% + #{$header-border-width});
    z-index: 999;

    .menu {
      li {
        text-align: right;

        a {
          color: $white;
          text-transform: uppercase;
          font-weight: 600;
          display: block;
          padding: 0.7rem .5rem;
          line-height: 1;
        }
      }
    }
  }
}

#hero {
  img {
    width: 100%;
  }
}

#main-content {
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  background-color: $light-gray;

  .set-height {
    height: auto;

    @include breakpoint(large) {
      height: 16rem;
    }
  }

  .image-container {
    display: block;
    width: 50%;
    height: 100%;
    background-image: url(../img/images/holdinghand.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    float: left;
  }
}

#main-footer {
  background-color: $footer-bg;
  color: white;
  padding: 4rem 0 6rem;

  text-align: center;

  p, a {
    color: white;
  }

  a {
    border-bottom: 1px solid #fff;
  }

  img {
    width: 600px;
    max-width: 100%;
    padding-bottom: 2rem;
  }

  .container {
    line-height: 1.8;
    font-weight: 600;

    strong {
      font-weight: 800;
    }
  }

  .button.to-top {
    border: 4px solid white;
    float: right;
    padding: 1rem;
    background-color: white;
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    color: $primary;

    &:hover {
      background-color: $primary;
      color: $white;
    }
  }
}

.hero-unit {
  position: relative;
}

.front-layout .top {
  padding-top: .5rem;

  @include breakpoint(large) {
    position: absolute;
    bottom: 1rem;
    width: 100%;
    padding-top: 0;

    .panels-ipe-editing & {
      position: static;
      clear: both;
    }
  }
}

.primary-box, .secondary-box, .split-box, .front-layout .top .panel-pane {
  background-color: white;
  display: block;
  width: 100%;
  padding: 1rem 1.5rem;
  margin: .5rem 0;

  &.split-box {
    background: none;
  }

  &.custom-intro {
    @include breakpoint(large) {
      z-index: 99;
      background-color: rgba($black, .65);
      color: white;

      .panels-ipe-editing & {
        background: white;
        color: black;
        margin-top: .5rem;
      }
    }
  }

  .logo {
    width: 100%;
  }
}


.secondary-box {
  background-color: $primary;

  h2, li, a {
    color: white;
  }
}

.split-box {
  padding: 0;
  margin: .5rem 0;

  &.three-fourths {
    .primary-box {
      width: 75.4%;
    }

    .last-element-split-box {
      width: 24.6%;
    }
  }

  .last-element-split-box {
    padding-left: .5rem;

    .primary-box, .secondary-box {
      width: 100%;
    }
  }

  .primary-box, .secondary-box, .last-element-split-box {
    width: 100%;
    float: left;
    margin: 0;
    display: block;

    @include breakpoint(large) {
      width: 50%;
    }
  }
}

.atlas {
  background: white;
  width: 100%;
  height: 14rem;
  float: left;
  margin: 0;
  display: block;
  border: 4px solid white;
  transition: 200ms ease-in-out;
  background: url(../img/icon/earth.png) center 3rem no-repeat $white;
  background-size: 5rem auto;


  @include breakpoint(medium) {
    background-size: 6rem auto;
    height: 16rem;
  }

  &:hover {
      border: 4px solid $primary;
  }

  span {
    width: 85%;
    margin: 0 auto;
    display: block;
    margin-top: 11rem;
    text-align: center;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: bold;

    @include breakpoint(medium) {
      font-size: 1.2rem;
      margin-top: 11rem;
    }
  }
}

#skip-link {
  background-color: white;
}

.view-display-id-panel_pane_1 {
  ul {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    li {
      width: 100%;
      margin: 0;
      padding: 0 0.5rem;
      @include breakpoint(medium) {
        width: 50%;
      }
      @include breakpoint(large) {
        width: 25%;
      }
    }
    span {
      min-width: 200px;
    }
  }
}
